body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: 'Verdana', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.carousel-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 1rem;
  border-radius: 5px;
}

/* .carousel-caption h3 {
  color: white;
} */

.carousel-caption p {
  color: white;
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.container {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}

.homeDetails {
  font-weight: 400;
  text-align: justify;
  color: #818181;
  font-size: small;
}