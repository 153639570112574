.image-container {
    position: relative;
    cursor: pointer;
  }
  
  .image-container img {
    transition: opacity 0.3s ease;
  }
  
  .image-container:hover img {
    opacity: 0.7;
  }
  
  .image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }
  
  .image-container .overlay i {
    color: white;
    font-size: 2em;
  }
  